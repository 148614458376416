import React, { useState } from 'react';
import './Faq.css'; // Import the CSS file for styling

export default function Faq() {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFaq = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "What services does Skyland Web offer?",
      answer:
        "At Skyland Web, we specialize in custom software development, web application development, mobile app development, cloud solutions, and IT consultancy services tailored to your business needs.",
    },
    {
      question: "How do I request a consultation for a custom project?",
      answer:
        "Simply visit our Contact Us page and fill out the inquiry form. Our team will get back to you within 24 hours to discuss your project requirements.",
    },
    {
      question: "Can Skyland Web integrate third-party APIs into my application?",
      answer:
        "Absolutely! Our team has extensive experience in API integration, ensuring seamless functionality between your application and external services.",
    },
    {
      question: "Does Skyland Web provide ongoing support and maintenance?",
      answer:
        "Yes, we offer comprehensive support and maintenance services to ensure your software runs smoothly and remains up-to-date.",
    },
    {
      question: "How long does it take to complete a software project?",
      answer:
        "Project timelines vary based on complexity and scope. We’ll provide an estimated timeline after our initial consultation.",
    },
    {
      question: "What industries does Skyland Web work with?",
      answer:
        "We cater to various industries, including finance, healthcare, real estate, retail, and more. Our solutions are designed to meet the unique needs of each industry.",
    },
    {
      question: "How do I upgrade my service plan or request additional features?",
      answer:
        "Contact our support team through support@skylandweb.com, and we’ll assist you with upgrades or feature enhancements.",
    },
    {
      question: "Does Skyland Web offer mobile app development?",
      answer:
        "Yes, we develop feature-rich mobile applications for iOS, Android, and cross-platform solutions to help your business reach a wider audience.",
    },
    {
      question: "How do I get in touch with Skyland Web’s support team?",
      answer:
        "You can reach us via our Contact Us page, email us at support@skylandweb.com, or call us at our support hotline.",
    },
    {
      question: "Is my data secure with Skyland Web?",
      answer:
        "Absolutely. Data security is our top priority. We follow industry best practices and use the latest technologies to safeguard your information.",
    },
  ];

  return (
    <div className="faq-container">
      <h1 className="faq-title">Frequently Asked Questions</h1>
      <div className="faq-items-container">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className={`faq-item ${openIndex === index ? 'open' : ''}`}
            onClick={() => toggleFaq(index)}
          >
            <h3 className="faq-question">
              {faq.question} <span>{openIndex === index ? '-' : '+'}</span>
            </h3>
            {openIndex === index && <p className="faq-answer">{faq.answer}</p>}
          </div>
        ))}
      </div>
    </div>
  );
}
