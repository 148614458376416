import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';

const VirtualAssistant = () => {
  return (
    <>
      <div>
        <NavBar />
      </div>
      <div className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24 " data-aos="zoom-in">
        {/* Main content */}
        <div className="text-center max-w-4xl mx-auto">
          <h1 className="text-3xl md:text-4xl font-bold text-gray-900 mb-6" >
            Discover the Future of Assistance with Our Virtual Assistant
          </h1>
          <p className="text-base md:text-lg text-gray-700 mb-8">
            Our virtual assistant services are designed to streamline your
            workflow, manage your tasks efficiently, and provide reliable
            support tailored to your needs. Whether you're a busy professional,
            an entrepreneur, or a small business owner, our virtual assistants
            are here to help you save time and enhance productivity.
          </p>
          <div className="flex justify-center mb-8">
            <a
              href="/contact"
              className="bg-blue-900 text-white py-2 px-6 rounded-lg text-base md:text-lg font-semibold transition-all duration-300 hover:bg-blue-600"
            >
              Hire a Virtual Assistant
            </a>
          </div>
          <div className="grid gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h2 className="text-xl md:text-2xl font-semibold text-gray-900 mb-4">
                Personalized Task Management
              </h2>
              <p className="text-sm md:text-base text-gray-700 mb-4">
                From managing emails and scheduling appointments to handling
                research and data entry, our virtual assistants are equipped to
                handle a wide range of tasks, ensuring you can focus on what
                matters most.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h2 className="text-xl md:text-2xl font-semibold text-gray-900 mb-4">
                Professional and Reliable Support
              </h2>
              <p className="text-sm md:text-base text-gray-700 mb-4">
                Our team of virtual assistants is not only skilled but also
                committed to providing top-notch support. We understand the
                importance of reliability and professionalism in every task we
                handle.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h2 className="text-xl md:text-2xl font-semibold text-gray-900 mb-4">
                Scalable Solutions for Every Need
              </h2>
              <p className="text-sm md:text-base text-gray-700 mb-4">
                Whether you need part-time assistance or full-time support, we
                offer flexible solutions tailored to your specific needs and
                budget, helping you grow and adapt as needed.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
};

export default VirtualAssistant;
