import React, { useState, useEffect } from "react";
import { HashLink } from "react-router-hash-link";
import { FaChevronDown, FaUser } from "react-icons/fa";
import Notiflix from "notiflix";
import axios from "axios";

const NavLinks = ({ onClick }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isVaDropdownOpen, setIsVaDropdownOpen] = useState(false);
  const [isPaymentDropdownOpen, setIsPaymentDropdownOpen] = useState(false);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const role = localStorage.getItem("userRole");
    if (role) {
      setUserRole(role);
    }
  }, []);

  const handleLogout = async () => {
    try {
      await axios.delete(
        "https://skyland-backend.vercel.app/api/v1/auth/logout"
      );
      Notiflix.Notify.success("Logout successful!");
      localStorage.removeItem("userRole");
      setUserRole(null);
      window.location.href = "/";
    } catch (err) {
      Notiflix.Notify.failure("Logout failed. Please try again.");
      console.error("Logout error:", err);
    }
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  const handleVaDropdownToggle = () => {
    setIsVaDropdownOpen((prevState) => !prevState);
  };

  const closeVaDropdown = () => {
    setIsVaDropdownOpen(false);
  };

  const handlePaymentDropdownToggle = () => {
    setIsPaymentDropdownOpen((prevState) => !prevState); // Handle Payment Gateway dropdown toggle
  };

  const closePaymentDropdown = () => {
    setIsPaymentDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".dropdown-wrapper")) {
        closeDropdown();
        closeVaDropdown();
        closePaymentDropdown();
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative dropdown-wrapper flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4">
      <div className="relative inline-block">
        <span
          className="px-4 font-extrabold text-gray-500 hover:text-blue-900 cursor-pointer"
          onClick={handleDropdownToggle}
        >
          SERVICES <FaChevronDown className="inline ml-1" />
        </span>
        {isDropdownOpen && (
          <div className="absolute left-0 mt-2 w-[300px] bg-white shadow-lg rounded-lg z-10">
            <div className="grid grid-cols-2 gap-2 p-4">
              {[
                "custom-software-development",
                "web-application-development",
                "mobile-app-development",
                "domain-hosting-services",
                "IT-consultations",
                "content-creation-services",
                "cybersecurity-services",
                "SEO-services",
                "digital-marketing-services",
                "accounting-services",
                "data-entry-services",
                "crypto-tracing-services",
                "outreach-services",
              ].map((service, index) => (
                <HashLink
                  key={index}
                  className="text-gray-700 hover:text-blue-900 flex items-center"
                  smooth
                  to={`/#${service}`}
                  onClick={() => {
                    closeDropdown();
                    onClick();
                  }}
                >
                  {service
                    .replace(/-/g, " ")
                    .replace(/\b\w/g, (char) => char.toUpperCase())}
                </HashLink>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="relative inline-block">
        <span
          className="px-4 font-extrabold text-gray-500 hover:text-blue-900 cursor-pointer"
          onClick={handleVaDropdownToggle}
        >
          SKYLAND WEB VA <FaChevronDown className="inline ml-1" />
        </span>
        {isVaDropdownOpen && (
          <div className="absolute left-0 mt-2 w-[300px] bg-white shadow-lg rounded-lg z-10">
            <div className="grid grid-cols-1 gap-2 p-4">
              <HashLink
                className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
                to="/skyland-va"
                onClick={() => {
                  onClick();
                  closeVaDropdown();
                }}
              >
                Hire Va Now
              </HashLink>
            </div>
          </div>
        )}
      </div>

      {/* Payment Gateway Dropdown */}
      <div className="relative inline-block">
        <span
          className="px-4 font-extrabold text-gray-500 hover:text-blue-900 cursor-pointer"
          onClick={handlePaymentDropdownToggle}
        >
          PAYMENT GATEWAY <FaChevronDown className="inline ml-1" />
        </span>
        {isPaymentDropdownOpen && (
          <div className="absolute left-0 mt-2 w-[300px] bg-white shadow-lg rounded-lg z-10">
            <div className="grid grid-cols-1 gap-2 p-4">
              <a
                className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
                href="https://skylandtransaction.netlify.app/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Get Your Own Gateway
              </a>
            </div>
          </div>
        )}
      </div>
      <HashLink
        className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
        to="/consultation#consultation"
        onClick={() => {
          onClick();
          closeDropdown();
        }}
      >
        FREE CONSULTATION
      </HashLink>
      <HashLink
        className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
        smooth
        to="/#about"
        onClick={() => {
          onClick();
          closeDropdown();
        }}
      >
        ABOUT
      </HashLink>

      <HashLink
        className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
        to="/contact#contact"
        onClick={() => {
          onClick();
          closeDropdown();
        }}
      >
        CONTACT US
      </HashLink>

      {userRole === "Admin" && (
        <HashLink
          className="px-4 font-extrabold text-gray-500 hover:text-blue-900"
          to="/dashboard"
          onClick={() => {
            onClick();
            closeDropdown();
          }}
        >
          Dashboard
        </HashLink>
      )}
      <HashLink
        className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl"
        smooth
        to="/get-demo#demo"
        onClick={() => {
          onClick();
          closeDropdown();
        }}
      >
        DEMO OUR PRODUCRS
      </HashLink>

      {userRole ? (
        <span
          className="px-4 font-extrabold text-gray-500 hover:text-blue-900 flex items-center cursor-pointer"
          onClick={() => {
            handleLogout();
            closeDropdown();
          }}
        >
          <FaUser className="inline mr-1" /> Logout
        </span>
      ) : (
        <HashLink
          className="px-4 font-extrabold text-gray-500 hover:text-blue-900 flex items-center"
          to="/login"
          onClick={() => {
            onClick();
            closeDropdown();
          }}
        >
          <FaUser className="inline mr-1" /> Login
        </HashLink>
      )}
    </div>
  );
};

export default NavLinks;
