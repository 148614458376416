// import React from "react";
// import img from "../images/Web-developer.svg";
// import { Link } from "react-router-dom";

// const About = () => {
//   return (
//     <>
//       <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id="about">
//         <div
//           className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left"
//           data-aos="fade-up"
//         >
//           <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
//             <img alt="card img" className="rounded-t float-right" src={img} />
//           </div>
//           <div
//             className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8"
//             data-aos="zoom-in"
//             data-aos-delay="500"
//           >
//             <h3 className="text-3xl  text-blue-900 font-bold">
//               We develop high quality bespoke web and mobile applications for
//               organizations, institutions.
//             </h3>
//             <div>
//               <p className="my-3 text-xl text-gray-600 font-semibold">
//                 Our team is well vast in software development and is ready to
//                 help develop the applications of your choice.
//               </p>
//             </div>

//             <div>
//               <p className="my-3 text-xl text-gray-600 font-semibold">
//                 We take responsibility for building custom software solutions
//                 that caters for automation of your business processes and
//                 improve efficiency.
//               </p>
//             </div>
//             <Link
//               to="/contact"
//               className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-2 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group"
//             >
//               Contact us
//               <svg
//                 className="w-4 h-4 ml-1 group-hover: translate-x-2"
//                 xmlns="http://www.w3.org/2000/svg"
//                 viewBox="0 0 20 20"
//                 fill="currentColor"
//               >
//                 <path
//                   fillRule="evenodd"
//                   d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
//                   clipRule="evenodd"
//                 ></path>
//               </svg>
//             </Link>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default About;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons"; // Import the required icon
import "./About.css"; // Ensure this CSS file is present in your styles directory
import NavBar from "./Navbar/NavBar";
import Footer from "./Footer";

export default function About() {
  const timelineData = [
    {
      title: "Founder",
      date: "2015 - 2016",
      description: "Skyland Web, a software company founded by Tanver Khan, was created with a vision to innovate and transform the tech industry. Tanver's passion for technology and development led to the birth of a company dedicated to delivering high-quality software solutions.",
      position: "left",
    },
    {
      title: "Our Start",
      date: "2016 - 2017",
      description: "Skyland Web's first major project was launched in 2016, providing advanced software solutions for businesses. Under the leadership of Tanver Khan, we quickly gained recognition for our expertise in building scalable and secure applications.",
      position: "right",
    },
    {
      title: "Our Vision",
      date: "2023 - 2027",
      description: "Skyland Web's vision for the next 5 years is to become a global leader in software development, helping businesses across industries leverage cutting-edge technologies like AI, cloud computing, and automation to achieve their goals.",
      position: "left",
    },
    {
      title: "Our Partners",
      date: "2015 - 2027",
      description: "Skyland Web has formed strategic partnerships with leading tech firms and software providers, including Microsoft, Google Cloud, and Amazon Web Services, enabling us to offer world-class solutions to our clients.",
      position: "right",
    }
  ];
  

  return (
    <>
 
    <div className=' mt-4' id="about">
      <div className='aboutTitle'>
        
        <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">
        Our Story
          </h2>
          <div className="flex justify-center">
            <div className="w-24 border-b-4 border-blue-900"></div>
          </div>
      </div>
      <div className='timeline'>
        {timelineData.map((item, index) => (
          <div key={index} className={`container ${item.position === "left" ? 'leftContainer' : 'rightContainer'}`}>
            <FontAwesomeIcon icon={faCircle} className="circle" />
            <div className="textBox">
              <h2>{item.title}</h2>
              <small>{item.date}</small>
              <p>{item.description}</p>
              <span className={item.position === "left" ? 'leftContainerArrow' : 'rightContainerArrow'}></span>
            </div>
          </div>
        ))}
      </div>
    </div>

    </>
    
  );
}
