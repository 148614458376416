import React from 'react';
import Clients from '../components/Clients';
import Cta from '../components/Cta';
import Footer from '../components/Footer';
import Hero from '../components/Hero';

import Portfolio from '../components/Portfolio';
import Services from '../components/Services';
import About from '../components/About';
import Faq from '../components/Faq/Faq';
import ClientReview from '../components/ClientReview/ClientReview';



const Home = () => {
    return (
        <>
            <Hero />
           
            <Services />
            <About />
            <Portfolio />
            <Faq />
            <Clients />
            {/* <ClientReview/> */}
            <Cta/>
            <Footer />
        </>

    )
}

export default Home;

