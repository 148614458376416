import { QRCodeCanvas } from "qrcode.react";
import React from "react";
import NavBar from "./Navbar/NavBar";
import Footer from "./Footer";


const QRCodeComponent = () => {
    const handleQRClick = () => {
        window.open("https://skylandtransaction.netlify.app/", "_blank");
    };

    return (
        <>
        <div>
            <NavBar />
        </div>
        <div className="flex flex-col items-center justify-center my-[150px]">
            <h2 className="text-2xl font-bold mb-4">Scan the QR Code</h2>
            <div
                onClick={handleQRClick}
                className="cursor-pointer p-4 border rounded-lg hover:shadow-lg transition-shadow duration-200"
            >
                <QRCodeCanvas
                    value="https://skylandtransaction.netlify.app/"
                    size={200}
                    level="H"
                    includeMargin={true}
                />
            </div>
            <p className="text-gray-500 mt-4">
                Click on the QR code to visit the Payment Gateway.
            </p>
        </div>
        <Footer />
        </>
    );
};

export default QRCodeComponent;
