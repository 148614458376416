// new new
import React from "react";
import img from "../images/web.svg";
import img1 from "../images/undraw_web_development_0l6v.svg";
import img2 from "../images/app.svg";
import img3 from "../images/hosting.svg";
import img4 from "../images/consultation.svg";
import img5 from "../images/undraw_content_team_re_6rlg.svg";
import img6 from "../images/undraw_security_re_a2rk.svg";
import img7 from "../images/seo.svg";
import img8 from "../images/undraw_mobile_marketing_re_p77p.svg";
import img9 from "../images/undraw_calculator_re_alsc.svg";
import img10 from "../images/undraw_growth_curve_re_t5s7.svg";
import img11 from "../images/undraw_crypto_portfolio_2jy5.svg";
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <div id="services" className="bg-gray-100 py-2 ">
      <section data-aos="zoom-in-down">
        <div className="my-4 py-4">
          <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">
            Services
          </h2>

          <div className="flex justify-center">
            <div className="w-24 border-b-4 border-blue-900"></div>
          </div>
          <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">
            We are deeply committed to the growth and success of our clients.
          </h2>
        </div>

        <div className="px-12" data-aos="fade-down" data-aos-delay="600">
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5">
            {/* customised web app */}
            <Link to="/contact">
              <div id="custom-software-development" className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group h-full">
                <div className="m-2 text-justify text-sm">
                  <img
                    alt="card img"
                    className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                    src={img}
                  />
                  <h2 className="font-semibold my-4 text-2xl text-center">
                    Custom Software Development
                  </h2>
                  <p className="text-md font-medium">
                    At Skyland Web, we assist you in crafting diverse and
                    sophisticated software solutions tailored to your business
                    requirements. By attentively listening to your ideas, we
                    meticulously implement and design custom software solutions
                    from the ground up, uniquely suited to your business. With
                    Skyland Web, you can trust that you'll receive top-notch
                    software and impeccable service with every project.
                  </p>
                </div>
              </div>
            </Link>
            {/* Web Development */}
            <Link to="/contact">
              <div id="web-application-development" className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group h-full">
                <div className="m-2 text-justify text-sm">
                  <img
                    alt="card img"
                    className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                    src={img1}
                  />
                  <h2 className="font-semibold my-4 text-2xl text-center">
                    Web Application Development
                  </h2>
                  <p className="text-md font-medium">
                    At Skyland Web, we go beyond conventional solutions to
                    create innovative web applications. Our skilled and
                    dedicated web development team understands your needs and
                    leverages modern web frameworks to develop high-value
                    business applications. Whether you need a simple website or
                    a complex web platform, we are here to turn your vision into
                    reality.
                  </p>
                </div>
              </div>
            </Link>

            {/* Mobile App Development */}
            <Link to="/contact">
              <div id="mobile-app-development" className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group h-full">
                <div className="m-2 text-justify text-sm">
                  <img
                    alt="card img"
                    className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                    src={img2}
                  />
                  <h2 className="font-semibold my-4 text-2xl text-center">
                    Mobile App Development
                  </h2>
                  <p className="text-md font-medium">
                    We develop high-quality, custom cross-platform mobile
                    applications that are robust and optimized for performance,
                    scalability, and accessibility.
                  </p>
                </div>
              </div>
            </Link>

            {/* Domain and Hosting Services */}
            <Link to="/contact">
              <div id="domain-hosting-services" className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group h-full">
                <div className="m-2 text-justify text-sm">
                  <img
                    alt="card img"
                    className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                    src={img3}
                  />
                  <h2 className="font-semibold my-4 text-2xl text-center">
                    Domain and Hosting Services
                  </h2>
                  <p className="text-md font-medium">
                    We provide domain registration and web hosting services to
                    individuals and organizations to enable them gain visibility
                    in the digital space.
                  </p>
                </div>
              </div>
            </Link>

            {/* IT Consultations */}
            <Link to="/contact">
              <div id="IT-consultations" className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group h-full">
                <div className="m-2 text-justify text-sm">
                  <img
                    alt="card img"
                    className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                    src={img4}
                  />
                  <h2 className="font-semibold my-4 text-2xl text-center">
                    General IT Consultations
                  </h2>
                  <p className="text-md font-medium">
                    Our IT consultations service provides expert guidance and
                    support for businesses and individuals looking to optimize
                    their technology solutions.
                  </p>
                </div>
              </div>
            </Link>

            {/* Content Creation Services */}
            <Link to="/contact">
              <div id="content-creation-services" className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group h-full">
                <div className="m-2 text-justify text-sm">
                  <img
                    alt="card img"
                    className="rounded-t group                      hover:scale-[1.15] transition duration-1000 ease-in-out"
                    src={img5}
                  />
                  <h2 className="font-semibold my-4 text-2xl text-center">
                    Content Creation Services
                  </h2>
                  <p className="text-md font-medium">
                    Custom logos, image generation, videos, banner designs,
                    leaflets/flyers, business cards, and marketing materials to
                    enhance your brand's visibility and appeal.
                  </p>
                </div>
              </div>
            </Link>

            {/* Cybersecurity Services */}
            <Link to="/contact">
              <div id="cybersecurity-services" className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group h-full">
                <div className="m-2 text-justify text-sm">
                  <img
                    alt="card img"
                    className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                    src={img6}
                  />
                  <h2 className="font-semibold my-4 text-2xl text-center">
                    Cybersecurity Services
                  </h2>
                  <p className="text-md font-medium">
                    Penetration testing to identify vulnerabilities and secure
                    your systems from potential threats.
                  </p>
                </div>
              </div>
            </Link>

            {/* SEO Services */}
            <Link to="/contact">
              <div id="SEO-services" className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group h-full">
                <div className="m-2 text-justify text-sm">
                  <img
                    alt="card img"
                    className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                    src={img7}
                  />
                  <h2 className="font-semibold my-4 text-2xl text-center">
                    SEO Services
                  </h2>
                  <p className="text-md font-medium">
                    Offline and online SEO strategies to boost your search
                    engine rankings and drive organic traffic to your site.
                  </p>
                </div>
              </div>
            </Link>

            {/* Digital Marketing Services */}
            <Link to="/contact">
              <div id="digital-marketing-services" className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group h-full">
                <div className="m-2 text-justify text-sm">
                  <img
                    alt="card img"
                    className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                    src={img8}
                  />
                  <h2 className="font-semibold my-4 text-2xl text-center">
                    Digital Marketing Services
                  </h2>
                  <p className="text-md font-medium">
                    Google, Facebook, Instagram, TikTok, Twitter, YouTube, and
                    influencer marketing to expand your online presence and
                    engage with your target audience.
                  </p>
                </div>
              </div>
            </Link>

            {/* Accounting Services */}
            <Link to="/contact">
              <div id="accounting-services" className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group h-full">
                <div className="m-2 text-justify text-sm">
                  <img
                    alt="card img"
                    className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                    src={img9}
                  />
                  <h2 className="font-semibold my-4 text-2xl text-center">
                    Accounting Services
                  </h2>
                  <p className="text-md font-medium">
                    Bookkeeping, virtual CFO services, and management of
                    corporate finance to streamline your financial operations.
                  </p>
                </div>
              </div>
            </Link>

            {/* Data Entry Services */}
            <Link to="/contact">
              <div id="data-entry-services" className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group h-full">
                <div className="m-2 text-justify text-sm">
                  <img
                    alt="card img"
                    className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                    src={img10}
                  />
                  <h2 className="font-semibold my-4 text-2xl text-center">
                    Data Entry Services
                  </h2>
                  <p className="text-md font-medium">
                    Professional data entry solutions to ensure accuracy and
                    efficiency in handling your data.
                  </p>{" "}
                </div>
              </div>
            </Link>

            {/* Crypto Tracing Services */}
            <Link to="/contact">
              <div id="crypto-tracing-services" className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                <div className="m-2 text-justify text-sm">
                  <img
                    alt="card img"
                    className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                    src={img11}
                  />
                  <h2 className="font-semibold my-4 text-2xl text-center">
                    Crypto Tracing Services
                  </h2>
                  <p className="text-md font-medium">
                    Skyland Web provides thorough crypto tracing services,
                    enabling you to monitor and analyze blockchain transactions
                    with ease. Our expert team ensures transparency and security
                    in your cryptocurrency operations, offering insights to
                    support informed decision-making.
                  </p>
                </div>
              </div>
            </Link>
            {/* Outreach Services */}
            {/* <Link to="/contact">
              <div id="outreach-services" className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group ">
                <div className="m-2 text-justify text-sm">
                  <img
                    alt="card img"
                    className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                    src={img10} // Replace with the appropriate image for Outreach Services
                  />
                  <h2 className="font-semibold my-4 text-2xl text-center">
                    Outreach Services
                  </h2>
                  <p className="text-md font-medium">
                    Skyland Web's Outreach Services are dedicated to helping you extend your
                    reach and impact within your community. We offer strategic planning,
                    community engagement, and outreach program development to connect you with
                    your target audience effectively. Our services include cold calling, cold emailing,
                    and personalized outreach strategies to ensure your message resonates. Let us help
                    you build strong, lasting relationships and foster meaningful connections through our expert outreach
                    services.
                  </p>
                </div>
              </div>
            </Link> */}

          </div>
        </div>
      </section>

      <section>
        <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6">
          <div
            className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left"
            data-aos="zoom-out"
          >
            <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
              <div className="text-blue-900 mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="72"
                  height="72"
                  viewBox="0 0 24 24"
                  className="fill-current"
                >
                  <path d="M2 12h2a7.986 7.986 0 0 1 2.337-5.663 7.91 7.91 0 0 1 2.542-1.71 8.12 8.12 0 0 1 6.13-.041A2.488 2.488 0 0 0 17.5 7C18.886 7 20 5.886 20 4.5S18.886 2 17.5 2c-.689 0-1.312.276-1.763.725-2.431-.973-5.223-.958-7.635.059a9.928 9.928 0 0 0-3.18 2.139 9.92 9.92 0 0 0-2.14 3.179A10.005 10.005 0 0 0 2 12zm17.373 3.122c-.401.952-.977 1.808-1.71 2.541s-1.589 1.309-2.542 1.71a8.12 8.12 0 0 1-6.13.041A2.488 2.488 0 0 0 6.5 17C5.114 17 4 18.114 4 19.5S5.114 22 6.5 22c.689 0 1.312-.276 1.763-.725A9.965 9.965 0 0 0 12 22a9.983 9.983 0 0 0 9.217-6.102A9.992 9.992 0 0 0 22 12h-2a7.993 7.993 0 0 1-.627 3.122z"></path>
                  <path d="M12 7.462c-2.502 0-4.538 2.036-4.538 4.538S9.498 16.538 12 16.538s4.538-2.036 4.538-4.538S14.502 7.462 12 7.462zm0 7.076c-1.399 0-2.538-1.139-2.538-2.538S10.601 9.462 12 9.462s2.538 1.139 2.538 2.538-1.139 2.538-2.538 2.538z"></path>
                </svg>
              </div>
              <h3 className="text-3xl text-blue-900 font-bold">
                We <span className="font-black">Build</span>
              </h3>
              <div>
                <p className="my-3 text-xl text-gray-600 font-semibold">
                  With over 5 years experience in software analysis and design
                  and a deep understanding of the latest IT trends and
                  solutions, we provide customized recommendations and
                  strategies to help you improve your operations, reduce costs,
                  and increase efficiency.
                </p>
              </div>
            </div>
            <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
              <div className="text-blue-900 mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="72"
                  height="72"
                  viewBox="0 0 24 24"
                  className="fill-current"
                >
                  <path d="m7.375 16.781 1.25-1.562L4.601 12l4.024-3.219-1.25-1.562-5 4a1 1 0 0 0 0 1.562l5 4zm9.25-9.562-1.25 1.562L19.399 12l-4.024 3.219 1.25 1.562 5-4a1 1 0 0 0 0-1.562l-5-4zm-1.649-4.003-4 18-1.953-.434 4-18z"></path>
                </svg>
              </div>
              <h3 className="text-3xl text-blue-900 font-bold">
                We <span className="font-black">Collaborate</span>
              </h3>
              <div>
                <p className="my-3 text-xl text-gray-600 font-semibold">
                  We can collaborate with your existing tech team to scale
                  existing software applications or design customized software
                  applications that suits your everyday need and simplifies
                  various processes.
                </p>
              </div>
            </div>
            {/*  Outreach services */}

          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;
